import { Money } from '@aw/shared/models';

import { JobTitle } from '@aw/prypco/enums';

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

export enum AmplitudeEventName {
  PARTNER_START = 'Partner Start',
  PARTNER_FORM_FILLING = 'Partner Form Filling',
  DIRECT_JOURNEY_STARTED = 'Direct Journey Started',
  DIRECT_STAGE_JOURNEY = 'Direct Stage Journey',
  DIRECT_ONBOARDING_CALCULATOR = 'Direct Onboarding Calculator',
  DIRECT_CALCULATOR_JOURNEY = 'Direct Calculator Journey',
  DIRECT_MORTGAGE_LEAD_CREATION = 'Direct Mortgage Lead Creation',
  DIRECT_APPLICATION_CREATION_STEP_1 = 'Direct Application Creation Step 1',
  DIRECT_APPLICATION_CREATION_STEP_2 = 'Direct Application Creation Step 2',
  DIRECT_APPLICATION_CREATION_STEP_3 = 'Direct Application Creation Step 3',
  DIRECT_APPLICATION_CREATION_GET_APPROVED = 'Direct Application Creation Get Approved',
}

export type AmplitudeEventPropertyValue =
  | 'button name'
  | 'agency name'
  | 'agent id'
  | 'application form id'
  | 'lead id'
  | 'employment situation'
  | 'monthly income amount'
  | 'relationship type'
  | 'expected loan amount'
  | 'expected property value amount'
  | 'expected down payment amount'
  | 'expected mortgage loan type'
  | 'loan duration';

export type AmplitudeEventProperty = AtLeastOne<
  Record<AmplitudeEventPropertyValue, string | number >
>;

export interface AmplitudeTrackEvent {
  eventInput: AmplitudeEventName;
  eventProperties: AmplitudeEventProperty;
}

export const trackingAllowedRoles = [
  JobTitle.Member,
  JobTitle.SalesAgent,
  JobTitle.SalesAgencyManager,
  JobTitle.MortgageBroker,
];
