export enum LeadType {
  Loan = 'loan',
  PropertySearch = 'propertySearch',
}

export enum LoanType {
  Personal = 'PERSONAL_LOAN',
  Auto = 'AUTO_LOAN',
  Overdraft = 'OVERDRAFT_LOAN',
}

export enum MortgageApplicationStatus {
  ApplicationWithdrawn = 'APPLICATION_WITHDRAWN',
  AwaitingDocuments = 'AWAITING_DOCUMENTS',
  AwaitingMouSpaSoaHon = 'AWAITING_MOU_SPA_SOA_HON',
  AwaitingValuation = 'AWAITING_VALUATION',
  CreditReview = 'CREDIT_REVIEW',
  DdaInsurancePending = 'DDA_INSURANCE_PENDING',
  Disbursed = 'DISBURSED',
  Expired = 'EXPIRED',
  IncomeAndLiabilities = 'INCOME_AND_LIABILITIES',
  PendingDownPayment = 'PENDING_DOWN_PAYMENT',
  PropertyAndMortgage = 'PROPERTY_AND_MORTGAGE',
  RejectedContinue = 'REJECTED_CONTINUE',
  RejectedWillNotContinue = 'REJECTED_WILL_NOT_CONTINUE',
  SelectingOffer = 'SELECTING_OFFER',
  Submit = 'SUBMIT_FOR_FOL',
  UpdateProfile = 'UPDATE_PROFILE',
  UploadDocuments = 'UPLOAD_DOCUMENTS',
  UploadFinalOfferLetter = 'UPLOAD_FINAL_OFFER_LETTER',
  UploadPreApproval = 'UPLOAD_PRE_APPROVAL',
  UploadPropertyDocuments = 'UPLOAD_PROPERTY_DOCUMENTS',
  ValuationInitiated = 'VALUATION_INITIATED',
  WipInBank = 'WIP_IN_BANK',
  NotEligible = 'NOT_ELIGIBLE',
}

export enum MortgageApplicationStatusTransitionKey {
  Closed = 'CLOSED',
  BankOutcome = 'BANK_OUTCOME',
  SubmitToBank = 'SUBMIT_TO_BANK',
}

export enum MortgageApplicationStage {
  Disbursal = 'DISBURSAL',
  PreApproval = 'PRE_APPROVAL',
  Property = 'PROPERTY',
  Closed = 'CLOSED',
}

export enum MortgageApplicantEmploymentSituation {
  CompanyEmployee = 'COMPANY_EMPLOYEE',
  SelfEmployed = 'SELF_EMPLOYED',
  Unemployed = 'UNEMPLOYED',
}

export enum MortgageApplicantBusinessType {
  Banking = 'BANKING',
  GeneralTrading = 'GENERAL_TRADING',
  ItServices = 'IT_SERVICES',
  Construction = 'CONSTRUCTION',
  Retail = 'RETAIL',
  Other = 'OTHER',
  Advertising = 'ADVERTISING',
  FoodAndBeverage = 'FOOD_AND_BEVERAGE',
  Insurance = 'INSURANCE',
}

export enum MortgageApplicantType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum UaeResidencyStatus {
  National = 'UAE_NATIONAL',
  Resident = 'RESIDENT',
  NonResident = 'NON_RESIDENT',
}

export enum MortgageApplicantRelationship {
  CoFinancee = 'CO_FINANCEE',
  CoApplicant = 'CO_APPLICANT',
}

export enum MortgageLoanType {
  Conventional = 'CONVENTIONAL',
  Islamic = 'ISLAMIC',
  NotSure = 'NOT_SURE',
}

export enum MortgageLiabilityType {
  CreditCard = 'CREDIT_CARD',
  Loan = 'LOAN',
  Mortgage = 'MORTGAGE',
}

export enum Language {
  English = 'en',
  Arabic = 'ar',
}

export enum CallDestination {
  Lead = 'LEAD',
  SalesAgent = 'SALES_AGENT',
}

export enum PersonalDocCategory {
  MortgageApplication = 'APPLICATION_FORMS',
  Passport = 'PASSPORT',
  EmiratesIdFront = 'EMIRATES_ID_FRONT',
  EmiratesIdBack = 'EMIRATES_ID_BACK',
  Visa = 'VISA',
  Salary = 'SALARY',
  BankStatement = 'BANK_STATEMENT',
  ResidencyProof = 'RESIDENCY_PROOF',
  TradeLicense = 'TRADE_LICENSE',
  CompanyBankStatement = 'COMPANY_BANK_STATEMENT',
  MemorandumOfAssociation = 'MEMORANDUM_OF_ASSOCIATION',
  AuditedFinancialStatement = 'AUDITED_FINANCIAL_STATEMENT',
  BusinessProfileLetterHead = 'BUSINESS_PROFILE_LETTERHEAD',
  BoardResolutionCopy = 'BOARD_RESOLUTION_COPY',
  UnsignedOffer = 'UNSIGNED_OFFER',
  SignedOffer = 'SIGNED_OFFER',
  PreApproval = 'PREAPPROVAL',
  BankProposal = 'BANK_PROPOSAL',
}

export enum PropertyDocCategory {
  TitleDeed = 'TITLE_DEED',
  FloorPlan = 'FLOOR_PLAN',
  Mou = 'MOU',
  SellerPassport = 'SELLER_PASSPORT',
  SellerEmiratesId = 'SELLER_EMIRATES_ID',
  SellerNationalId = 'SELLER_NATIONAL_ID',
  StatementOfAccount = 'STATEMENT_OF_ACCOUNT',
  Other = 'PROPERTY_OTHER',
}

export type MortgageApplicationDocCategory =
  | PersonalDocCategory
  | PropertyDocCategory;

export enum CalendlyEventType {
  MortgageDiscussion = 'MORTGAGE_DISCUSSION',
}

export enum BusinessUnit {
  Corporate = 'CORPORATE',
  Mortgage = 'MORTGAGE',
  Blocks = 'BLOCKS',
  Services = 'SERVICES',
}

export enum LeadChannel {
  Onboarding = 'ONBOARDING',
  BackOffice = 'BACKOFFICE',
  Salesforce = 'SALESFORCE_ADAPTER',
}

export enum ServicesProductType {
  GoldenVisa = 'GOLDEN_VISA',
  InvestorVisa = 'INVESTOR_VISA',
  RNPL = 'RNPL',
}

// TODO: !REMOVE
export enum NotificationType {
  OnfidoResult = 'ONFIDO_RESULT',
  BankOfferUpdated = 'BANK_OFFER_UPDATED',
}

export enum RentalDetailsRentalType {
  Estimated = 'ESTIMATED',
  Actual = 'ACTUAL'
}